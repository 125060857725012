import React, { useState, useMemo, useEffect } from 'react';
import { PaletteColorOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area, Bar, ComposedChart, ReferenceLine, ReferenceArea, Label } from 'recharts';
import logo from './VoteVibeLogoTransparent.png';
import { blue, red } from '@mui/material/colors';
import * as VoteVibeApi from '../../api/votevibeApi';
import { Stack, Switch } from '@mui/material';


const sentimentAll = 1000;
//const sentiments = {0: "Anger", 1: "Anticipation", 2: "Disgust", 3: "Fear", 4: "Joy", 5: "Love", 6: "Optimism", 
//                7: "Pessimism", 8: "Sadness", 9: "Surprise", 10: "Trust"}
const sentiments = {0: "Supportive", 1: "Critical", 2: "Uncertain", 3: "Emotional"}
//const sentimentColors = [
//  '#008017',
//  '#b80000',
//  '#0023d4',
//  '#b500d1',
//  '#8b0028',
//  '#7a2aff',
//  '#ffaa23',
//  '#98ff98',
//  '#8b0028',
//  '#7a2aff',
//  '#ffaa23',
//];
const sentimentColors = [
  '#6fc96f',
  '#b50034',
  '#7a2aff',
  '#ffaa23',
]
const dummyData : VoteVibeApi.DailySentiment[] = [{date: 0, sentiments: [] }]

// Create a theme with a light blue background
const themeDark = createTheme({
  palette: {
    mode: 'dark',
    primary: blue,
    secondary: red,
    background: {
      default: '#141c1e',
    },
  }
});
const themeLight = createTheme({
  palette: {
    mode: 'light',
    primary: blue,
    secondary: red,
    background: {
      default: '#ffffff',
    },
  },
});

export async function fetchApi() : Promise<[VoteVibeApi.DailySentiment[], VoteVibeApi.DailySentiment[]]> {
  let output : [VoteVibeApi.DailySentiment[], VoteVibeApi.DailySentiment[]] = [dummyData, dummyData];
  var SentimentsApi = new VoteVibeApi.Api({baseUrl: 'https://api.unixile.de'});
  return SentimentsApi.sentiments.listSentimentBuckets().then((result) => {
    console.log(result);
    return [(result.data.find(c => c.trump_harris === 0)?.daily_sentiments ?? dummyData),
      (result.data.find(c => c.trump_harris === 1)?.daily_sentiments ?? dummyData)];
  });
}


function App() {
  const [useLightTheme, setUseLightTheme] = useState(false);
  const [selectedCandidate, setSelectedCandidate] = useState('Trump');
  const [selectedSentiment, setSelectedSentiment] = useState(1000);
  const [trumpData, setTrumpData] = useState<VoteVibeApi.DailySentiment[]>(dummyData);
  const [harrisData, setHarrisData] = useState<VoteVibeApi.DailySentiment[]>(dummyData);

  const data = selectedCandidate === 'Trump' ? trumpData : harrisData;

  const handleThemeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseLightTheme(!event.target.checked);
  };

  const filteredData = useMemo(() => {
    if (selectedSentiment === sentimentAll) {
      return data;
    }
    return data.map(item => ({
      date: item.date,
      sentiments: [ item.sentiments?.find(s => s.key === selectedSentiment) ]
    }));
  }, [data, selectedSentiment]);

  useEffect(() => {
    console.log('useeffect');
    fetchApi().then((data) => {
      console.log('then', data);
      setTrumpData(data[0]);
      setHarrisData(data[1]);
    });
  }, []);

  return (
    <ThemeProvider theme={useLightTheme ? themeLight : themeDark}>
      <CssBaseline />
      <Container maxWidth='lg' sx={{ }}>
        <Box position='absolute' top={'12%'} right={10} sx={{ display: 'flex', flexDirection: 'row', alignContent:'center', alignItems: 'center'}} >
          <Typography sx={{opacity:0.5}}>Use Dark Mode</Typography>
          <Switch
            checked={!useLightTheme}
            onChange={handleThemeChange}
            inputProps={{ 'aria-label': 'controlled' }}
          />
        </Box>
        <Box sx={{ mt:1, backgroundColor:'#0000000' }}>
          <Card elevation={0} sx={{ backgroundColor:'transparent', boxShadow: 'none', position:'relative'}}>
            <CardContent sx={{pt:0, height:'100vh'}}>
              <Box sx={{ display: 'flex', flexDirection: 'column', alignContent:'center', alignItems: 'center', height:'15%'}}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignContent:'center', alignItems: 'center', gap: 2, mb: 2, height:'100%' }}>
                  <Box sx={{ display: 'flex', flex:'5 1', maxWidth:'30%' }}>
                    <Button variant='outlined' size='large' sx={{ borderWidth: '4px', m:0, opacity: selectedCandidate === 'Trump' ? '1' : '0.4', fontSize:20, fontWeight:600,
                      backgroundColor: selectedCandidate === 'Trump' ? '#ff000022' : 'inherit'}}
                      onClick={() => setSelectedCandidate('Trump')}
                      color={selectedCandidate === 'Trump' ? 'secondary' : 'inherit'}
                    >
                      Trump
                    </Button>
                  </Box>
                  <Box sx={{ display: 'flex', height:'100%', maxHeight:'100px', maxWidth:'100px' }}>
                      <img src={logo} style={{ width:'100%', maxWidth:'100%', maxHeight:'100%' }}/>
                  </Box>
                  <Box sx={{ display: 'flex', flex:'5 1', maxWidth:'30%' }}>
                    <Button variant='outlined' size='large' sx={{ borderWidth: '4px', opacity: selectedCandidate === 'Harris' ? '1' : '0.4', fontSize:20, fontWeight:600,
                      backgroundColor: selectedCandidate === 'Harris' ? '#0000ff33' : 'inherit'}}
                      onClick={() => setSelectedCandidate('Harris')}
                      color={selectedCandidate === 'Harris' ? 'primary' : 'inherit'}
                    >
                      Harris
                    </Button>
                  </Box>
                </Box>
              </Box>
              <ResponsiveContainer height="70%" aria-role='graph'>
                <AreaChart data={filteredData} style={{ zIndex: 1}} >
                  <defs>                    
                    <linearGradient id="color0" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#98ff98" stopOpacity={0.8}/>
                      <stop offset="100%" stopColor="#98ff98" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="color1" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#8b0028" stopOpacity={0.8}/>
                      <stop offset="100%" stopColor="#8b0028" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="color2" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#7a2aff" stopOpacity={0.8}/>
                      <stop offset="100%" stopColor="#7a2aff" stopOpacity={0}/>
                    </linearGradient>
                    <linearGradient id="color3" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="#ffaa23" stopOpacity={0.8}/>
                      <stop offset="100%" stopColor="#ffaa23" stopOpacity={0}/>
                    </linearGradient>
                  </defs>
                  <XAxis dataKey={data => {var dt = (new Date(data['date'])); var date = dt.getDate(); return date + '/' + (dt.getMonth()+1)} } stroke={useLightTheme ? '#000000' : '#ffffff'} strokeWidth={1}/>
                  {/*<YAxis stroke='#ffffff'/>*/}
                  <Tooltip contentStyle={{'color':'black'}}/>
                  <Legend verticalAlign='top'/>
                  {Object.entries(sentiments).map(([idx, sentiment]) => (
                    <Area
                      key={sentiment}
                      type="monotone"
                      name={sentiment}
                      dataKey={data => {
                        var sentimentData = data?.sentiments?.find((s: VoteVibeApi.Sentiment) => s ? s['key'] == +idx : false);
                        return sentimentData ? sentimentData['count'] : 0;
                      }}
                      stroke={sentimentColors[+idx]}
                      strokeWidth={3}
                      activeDot={{ r: 4 }}
                      dot={false}
                      fill={"url(#color"+ idx +")"}
                    />
                  ))}
                  <ReferenceLine x={'5/11'} strokeWidth={3} stroke={useLightTheme ? '#000000aa' : '#ffffffaa'} >
                    <Label value='Election' position='insideTop' style={{transform:'translate(-37px, 20px)', fontWeight:600}} ></Label>
                  </ReferenceLine>
                </AreaChart>
              </ResponsiveContainer>
              
              <Box my='30px' sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', height:'10%', maxWidth:'100%' }}>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 1, mb: 2 }}>
                  <Stack 
                    spacing={1}
                    direction="row"
                    useFlexGap
                    sx={{ flexWrap: 'wrap', alignItems:'center' }}>
                    <Button
                      onClick={() => setSelectedSentiment(sentimentAll)}
                      sx={{color: '#ffffff', backgroundColor: selectedSentiment === sentimentAll ? '#3464eb' : (useLightTheme ? '#616161' : '#333333')}} 
                    >
                      All
                    </Button>
                    {Object.entries(sentiments).map(([idx, sentiment]) => (
                      
                      <Button
                        key={+idx}
                        onClick={() => setSelectedSentiment(+idx)}
                        sx={{color: '#ffffff', backgroundColor: +idx == selectedSentiment ? sentimentColors[+idx] : (useLightTheme ? '#616161' : '#333333')}}
                      >
                        {sentiment}
                      </Button>
                    ))}
                  </Stack>
                </Box>
              </Box>
            </CardContent>
          </Card>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default App;

